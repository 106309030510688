.space {
    width: 100%;
    min-height: 100vh;
    height : 100%;
    padding-left: 260px;
    background-color: var(--lightBlue);
}

@media (max-width: 768px){
    .space {
        padding-left: 0;
    }
}

.btn-primary {
    @apply text-pure bg-primary font-medium rounded-lg cursor-pointer disabled:cursor-not-allowed duration-300 ease-in-out; 
}
.btn-primary:hover {
    @apply bg-primaryHover;
}

.btn-secondary {
    @apply border border-transparent bg-secondary text-pure font-medium rounded-lg cursor-pointer disabled:cursor-not-allowed duration-300 ease-in-out; 
}
.btn-secondary:hover {
    @apply border-secondary text-black bg-transparent;
}

.btn-red {
    @apply text-pure font-medium rounded-full cursor-pointer disabled:cursor-not-allowed bg-red-500; 
}

.btn-green-circle , .btn-red-circle , .btn-primary-circle {
    @apply bg-green-500 w-[25px] h-[25px] rounded-full flex items-center justify-center text-white text-base cursor-pointer disabled:cursor-not-allowed;
}

.btn-red-circle {
    @apply bg-red-500 hover:bg-red-600;
}
.btn-primary-circle {
    @apply bg-primary hover:bg-primaryHover;
}
.btn-green-circle i, .btn-red-circle i {
    @apply translate-y-[1px];
}

.section-heading {
    @apply font-semibold md:text-3xl text-2xl capitalize text-blueText; 
}

.heading {
    @apply font-semibold sm:text-xl text-lg capitalize text-blueText; 
}

.heading-sm{
    @apply font-semibold text-base text-blueText;
}

.icon-sm {
    @apply w-[20px] h-[20px] text-[20px]
}

.icon-lg {
    @apply w-[35px] h-[35px] text-[35px]
}

.circle-icon {
    @apply bg-white flex items-center justify-center w-[70px] h-[70px] rounded-full;
    box-shadow: 0px 0px 20px 0px #2c2c2c33;
}
.circle-icon i , .circle-icon svg , .circle-icon img {
    @apply w-[35px] h-[35px] text-[35px]
}

@media (max-width: 560px) {
    .circle-icon {
        width: 50px;
        height : 50px;
    }
    .circle-icon i , .circle-icon svg , .circle-icon img {
        @apply w-[25px] h-[25px] text-[25px]
    }
}

.select-box{
    @apply py-2 px-2 rounded-full border w-[150px] border-primary sm:text-base text-sm ; 
}

.shadow-bg {
    @apply bg-pure rounded-lg shadow-md border;
}

.shadow-bg-2{
    @apply bg-pure rounded-lg shadow-md border;
}
.shadow-bg-2:hover{
    @apply shadow-lg;
}

.accept , .cancel {
    @apply bg-red-500 w-[20px] h-[20px] rounded-full flex items-center justify-center text-white text-base cursor-pointer disabled:cursor-not-allowed;
}
.cancel {
    @apply bg-green-500 hover:bg-green-600;
}

.total-count , .docs-count{
    @apply bg-gray-100 rounded-md px-2 text-lg font-semibold;
}

.input  , .copy-input{
    @apply border py-3 px-3 text-gray-600 rounded-md outline-none w-full ;
}
.input::placeholder , .copy-input::placeholder {
    color : rgb(163, 163, 163);
}
.input:focus{
    border-color: var(--primary);
}

table {
    white-space: nowrap;
}

::-webkit-scrollbar{
    width: 5px;
}
::-webkit-scrollbar-track{
    background-color: white;
}
::-webkit-scrollbar-thumb{
    background-color: gray;
}
::-webkit-scrollbar-thumb:hover{
    background-color: var(--primary);
}


.border-gradient {
    position: relative;
}
.border-gradient::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 5px; 
    padding: 2px; 
    background: var(--primaryGradient); 
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude; 
}


.docsCount {
    @apply bg-gray-100 flex items-center justify-center py-1 rounded-md px-3
}


/* FORMIK/FORM */
.form-row {
    @apply flex flex-col gap-1.5 flex-1 w-full ;
}
.form-row label , .label {
    @apply font-semibold text-gray-600;
}
.form-row input , .form-row select , .form-row textarea , .input {
    @apply border py-3 px-3 text-gray-600 rounded-md outline-none w-full;
    
}
.form-row input:focus-within , .form-row select:focus-within , .form-row textarea:focus-within , .input:focus-within {
    @apply border-primary;
}
.form-row .error-msg , .error-msg {
    @apply text-red-500 text-sm ;
}
.border-red {
    @apply border border-red-500;
}
.input-error {
    border: 1px solid #ff0000 !important; /* Red border for error */
}


.popup {
    animation: show 0.15s ease-in-out;
}

@keyframes show {
    from {
        transform: scale(0.3);
    }
    to {
        transform: scale(1);
    }
}