.sidebar {
    transition: 0.3s all ease-in-out;
}
.sidebar.show {
    left : 0;
}
.sideMenu-list{
    @apply flex flex-col;
}
.sideMenu-item {
    @apply py-3
}
.sideMenu-item a , .signout , .sideMenu-item > div{
    @apply flex items-center gap-3 hover:text-primary cursor-pointer relative pl-8;
}
.sideMenu-item i {
    @apply text-lg;
}

.sideMenu-item.active {
    @apply text-pure w-[95%] py-4 rounded-tr-full rounded-br-full bg-primary hover:text-pure ;
}

.sideMenu-item.active a , .sideMenu-item.active > div{
    @apply hover:text-pure ;
}


.sideMenu-item-angle{
    @apply absolute top-1/2 right-0 -translate-y-[45%] text-lg text-grayText;
}

/* DROP MENU */

.dropMenu {
    @apply pl-16 pt-1 pb-2 flex flex-col gap-4;
}

.dropMenu-item a{
    @apply flex items-center gap-3 text-dark hover:text-primary;
}
.dropMenu-item a:hover .dot {
    @apply bg-primary;
}

.dropMenu-item.drop-active a {
    @apply text-secondary;
}
.dropMenu-item.drop-active a .dot {
    @apply bg-secondary;
}

.dropMenu-item .dot {
    @apply w-2 h-2 rounded-full bg-dark;
}

.dropMenu-item.drop-active .dot {
    @apply bg-secondary;
}